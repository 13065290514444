@import "./Fonts.scss";
@import "./Breakpoints.scss";
@import "./Variables.scss";


.project {
  display: block;
  padding-bottom: 3rem;
  
  .project_summary_container {
    padding-top: 2rem;
    clip-path: fill-box;

    @include media ("<=phone") {
      padding: 1.5rem 1rem 0
    }
    
    .title {
      font-family: $GeneralSans-Bold;
      font-size: 2rem;
      text-align: center;
      text-transform: uppercase;

      @include media ("<=phone") {
        font-size: 1.25rem;
        text-align: left;
      }
    }
    
    .tags {
      font-size: 1.5rem;
      // height: 24px;
      line-height: 1.5;
      text-align: center;
      
      
      @include media ("<=phone") {
        font-size: 13px;
        text-align: left;
        // height: 18px;
      }
    }
  }

  .project_banner {
    display: flex;
    .project__item__pic-wrap {
      position: relative;
      width: 100%;
      padding-top: 50%;
      overflow: hidden;
      

      @include media ("<=phone") {
        padding-top: 100%;
      }
    }
    
    .project__item__img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      

    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;

      @include media ("<=phone") {
        min-height: 15rem;
      }
    }
  }

  .project_info_container {
    padding: 4rem 4rem 5rem;
    display: flex;
    justify-content: flex-start;

    @include media ("<=phone") {
      padding: 2rem 1rem 2.5rem;
      
    }

    .info {
      font-family: $RecklessLight;
      font-size: 1.5rem;
      line-height: 1.25;
      text-align: center;
      width: 68%;

      @include media ("<=phone") {
        font-size: 1.25rem;
        text-align: left;
        width: 100%;
      }

      &.soon {
        font-size: 3rem;

        @include media ("<=phone") {
          font-size: 1.5rem
        }
      }
    }

    .info_left {
      font-family: $RecklessLight;
      font-size: 1.5rem;
      line-height: 1.25;
      text-align: left;
      width: 45%;

      @include media ("<=phone") {
        font-size: 1.25rem;
        text-align: left;
        width: 100%;
      }
    }
  }

  .project_grid_container {

    .hd_cover {
        display: flex;
        width: 100%;
        max-width: 100%;
        
        min-height: 100%;
        object-fit: cover;
      }


    .project__grid__pic-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      padding: 0 2rem;

      @include media ("<=phone") {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        padding: 0 1rem;
      }

      .project__item__img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
      }
  }
  }
}


iframe {
      
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;

      @include media ("<=phone") {
        min-height: 15rem;
      }
    }

      
.frame-single {
            img {
              position: relative;
              width: 100%;

              @include media ("<=phone") {
                width: 200%;
                left: -50%;
              }
            }
      }

.padding-bottom {
  padding-bottom: 2rem;

  @include media ("<=phone") {
    padding-bottom: 1rem;
  }
}



