
$black: #000000;
$white: #ffffff;
$yellow: #fff3d8;

$beige: #F3F6DA;
$parrotGreen: #A1C345;
$darkGreen: #0F5B45;

$pauseBG: #FEECE7;
$selfmattersBG: #F1F0F8;
$laundaryBG: #DBF5F9;
$oyekakeBG: #fde5c6;
$mumkinBG: #fbf8d5;
$trulyBG: #f5e8cb;
$aatBG: #FEECE7;
$kinstraktoBG: #FEECE7;
$atlbtlftlBG: #FEECE7;
$khotasikkaBG: #FEECE7;
$awakefulBG: #FEECE7;
$topicalBG: #FEECE7;


.pauseBG {
  background-color: $pauseBG;
}

.selfmattersBG{
  background-color: $selfmattersBG;
}

.laundaryBG {
  background-color: $laundaryBG;
}
