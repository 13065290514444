@import "./fonts.scss";
@import "./breakpoints.scss";
@import "./Variables.scss";

.cards {
  /* padding: 0.5rem; */
  background: transparent;
}

h1 {
  text-align: center;
}


.card_title {
  font-family: $GeneralSans-Bold;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;

  @include media ("<=phone") {
    font-size: 1.125rem;
  }
}

.see_more {
  font-family: $GeneralSans-Bold;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 2rem;
  text-decoration: none;
  color: black;
  display: block;

  @include media ("<=phone") {
    font-size: 1.125rem;
  }
}

.cards__container {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
}

.cards__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 6rem 1rem;
  grid-row-gap: 3rem;  

  @include media ("<=phone") {
    display: flex;
    flex-direction: column;
    grid-row-gap: 2rem;
    padding: 2rem 1rem;
  }
}

.cards__item {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  justify-content: center;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0;
  overflow: hidden;
  text-decoration: none;
  position: relative;
  // border-radius: 10px;

  @include media ("<=phone") {
    // padding: 1.5rem .5rem;
  }
  }


.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 80%;
  overflow: hidden;
  border-radius: 10px;
  z-index: 1;

  @include media ("<=phone") {
    padding-top: 125%;
  }
}


.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;

  :hover {
  transform: scale(1.1);
}
}
.cards__item__info {
  padding: 16px 0 0 0;
  color: #000000;
  position: absolute;
  bottom: 0;

  @include media ("<=phone") {
    padding: 10px 0 0 0;
    position: relative;
    // bottom: 0;
  }

  .title {
  font-family: $GeneralSans-Bold;
  font-size: 1.35rem;
  // height: 22px;
  line-height: 1;
  text-transform: uppercase;
  overflow: hidden;

  @include media ("<=phone") {
    font-size: 14px;
    height: 16px;
  }
}
.text {
  font-size: 24px;
  // height: 48px;
  line-height: 1.25;
  // overflow: hidden;

  @include media ("<=phone") {
    font-size: 22px;
    // height: 30px;
  }
}

.tags {
  font-size: 16px;
  height: 24px;
  line-height: 1.25;
  overflow: hidden;

  @include media ("<=phone") {
    font-size: 13px;
    height: 18px;
  }
}

}