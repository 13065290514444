@import "./Fonts.scss";
@import "./Breakpoints.scss";
@import "./Variables.scss";


.info-page {
  display: block;
  // padding-bottom: 4rem;
  

  .logo-container {
    width: 100%;
    align-items: center;
    height: 50vh;
    // background-color: #A1C345;
    background-color: $beige;

    @include media ("<=phone") {
      padding: 2.25rem;
    }

    .rocking-logo {
      background-image: url('../images/rocking-r.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 80%;
      animation: rocking 4s; 
      animation-iteration-count: infinite;
      -webkit-transform-origin: bottom center;
      transform-origin: bottom center;
      top: 25%;
      position: relative;
    }
    @keyframes rocking {
      0%{-webkit-transform: rotate(5deg);}
      50%{-webkit-transform: rotate(-5deg);}
      100%{-webkit-transform: rotate(5deg);}
    }
  }
  
  .info_page_container {
    padding: 5rem 4rem;
    display: flex;
    justify-content: flex-start;

    @include media ("<=phone") {
      padding: 2rem 1rem 2.5rem;
      
    }

    .info {
      font-family: $RecklessLight;
      font-size: 3rem;
      line-height: 1.25;
      text-align: center;
      width: 68%;

      @include media ("<=phone") {
        font-size: 1.5rem;
        text-align: left;
        line-height: 1.5;
        width: 100%;
      }
    }
  }
  .info_page_container_mini {
      padding: 6rem 15rem;
      overflow: hidden;
      height: 100%;
      justify-content: center;

      @include media ("<=phone") {
        padding: 4rem 1rem;
      }

      a{
      text-decoration: none;
      color: black;
    }

      h2 {
        font-family: $RecklessLight;
        font-size: 3rem;
        line-height: 1.5;
        text-align: center;

        @include media ("<=phone") {
          font-size: 1.5rem;
          text-align: left;
        }
      }

      h3 {
        font-family: $GeneralSans-Bold;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;

        @include media ("<=phone") {
          font-size: 14px;
          text-align: left;
        }
      }
      
      div {
          font-family: $RecklessLight;
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;

        @include media ("<=phone") {
          font-size: 1.25rem;
          text-align: left;
        }
        }

      
    }
}