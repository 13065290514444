@import "./fonts.scss";
@import "./breakpoints.scss";
@import "./Variables.scss";


#previous_project {
  padding: 0 1rem 3rem 2rem;
    // width: 50%;
    // grid-area: previous_project;

    @include media ("<=phone") {
    padding: 0 1rem 2.5rem;
    width: 90%;
    display: none;
  }

 .previous_project {
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  text-decoration: none;
  // border-top: 1.5px solid black;
  // border-bottom: 1.5px solid black;
  padding: 2rem 0;

  @include media ("<=phone") {
    position: relative;
    padding: 1rem 0;
  }

  .previous-tab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media ("<=phone") {
      width: 100%;
    }

   

    h3{
    font-family: $GeneralSans-Bold;
    font-size: 1.35rem;
    line-height: 1.5;
    text-transform: uppercase;
    overflow: hidden;

    @include media ("<=phone") {
      font-size: 14px;
      height: 20px;
    }
    }
  //    ::after {
  //     content: "→";
  // display: block;
  // // width: 45px;
  // // height: 2px;
  // // background-color: black;
  // // transform:  translate(15px, 4px);
  // // //  transform: rotate(135deg);
  // // left: 0;
  // // top: 0;
  //   }
  }
  
  .cards__item__info {
  padding: 16px 0 0 0;
  color: #000000;
  position: relative;
  bottom: 0;

  @include media ("<=phone") {
    padding: 10px 0 0 0;
    position: relative;
    // bottom: 0;
  }

  .title {
    font-family: $GeneralSans-Bold;
    font-size: 1.35rem;
    line-height: 1.5;
    text-transform: uppercase;
    overflow: hidden;
  
    @include media ("<=phone") {
      font-size: 14px;
      height: 20px;
    }
  }

  .text {
    font-size: 24px;
    line-height: 1.25;

    @include media ("<=phone") {
      font-size: 22px;
    }
  }
}
  }

  .details {
    width: 100%;
  }

  .cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 80%;
  overflow: hidden;
  border-radius: 10px;
  z-index: 1;

  @include media ("<=phone") {
    padding-top: 75%;
  }
}


.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;

  :hover {
  transform: scale(1.1);
}
}
}

.arrow-wrapper {
  width: 65%;
  overflow: hidden;
  height: 1rem;
   display: none;

  @include media ("<=phone") {
    width: 55%;
    display: block;
  }
  


.previous-arrow {
  background-image: url('../images/arrow.svg');
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  width: 100%;
  float: right;
  // padding: 1rem 0 1rem 1rem;
 

  @include media ("<=phone") {
    min-height: 100%;
    position: relative;
    // width: 490%;
  // padding: 1rem 0 1rem 1rem;
  }
}
}