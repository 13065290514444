@import "./Breakpoints.scss";
@import "./Variables.scss";

.footer-container {
  padding: 4rem 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  text-align: center;

  @include media ("<=phone") {
    padding: 2rem 1rem 5rem;
  }
  
  h1 {
  opacity: 1;
  text-align: left;
  font-size: 3rem;
  line-height: 1.25;
  font-weight: normal;
  z-index: 10;
  text-align: center;
  // width: 70%;
  padding-bottom: 4rem;

  @include media ("<=phone") {
    font-size: 1.5rem;
    text-align: left;
    width: 100%;
    line-height: 1.2;
    padding-bottom: 3rem;

  }

  a{
      text-decoration: none;
      color: black;
    }

  .reveal-text {
    visibility: hidden;
  }

  .line {
    height: 70px;
    position: relative;
    overflow: hidden;
    margin-bottom: .5rem;
    width: 100vw;
    // display: flex;

    @include media ("<=tablet") {
      height: 8vh;
    }

    @include media ("<=phone") {
      height: 45px;
    }
  }
  }

  .footer-link-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;

    @include media ("<=phone") {
      flex-wrap: wrap;
    }

    .footer-links {
      font-size: 1.5rem;
      justify-content: center;
      text-decoration: none;
      color: black;
      font-family: 'GeneralSans-Bold';
      text-transform: uppercase;
      cursor: pointer;

      @include media ("<=phone") {
        font-size: 1.25rem;
        width: 50%;

        &:first-child {
          text-align: left;
        }
        &:nth-child(2) {
          text-align: right;
        }
        &:nth-child(4) {
          text-align: right;
        }
        &:nth-child(3) {
          text-align: left;
        }
      }
    }
  }
  
}

.footer-cta {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1rem;
  text-align: left;
  

  @include media ("<=phone") {
  width: 100%;
  justify-content: space-between;
  gap: 0;
  }
}

.footer-cta {
  h4 {
    font-size: 2rem;

    @include media ("<=phone") {
      font-size: 1.2rem;
    }
  }

 p {
    font-family: 'GeneralSans-Semibold' !important;
    font-weight: 600;
    font-size: 1.5rem;

    @include media ("<=phone") {
      font-size: 1rem;
    }
  }
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #000000;
  font-size: 1.75rem;
  text-decoration: none;
  display: block !important;
  cursor: pointer;

  &__header {
    font-family: GeneralSans-Bold;
    text-transform: uppercase;
    font-size: 1.5rem;

    @include media ("<=phone") {
      font-size: 1rem;
    }
  }

  @include media ("<=phone") {
    font-size: 1.5rem;
  }
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex !important;
  align-items: baseline;
  justify-content: space-around;
  position: relative;
  padding: 1rem 3rem;
  width: 75%;
  z-index: 999;
  border-radius: 30px;
  border: 1px solid black;

  @include media ("<=phone") {
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: $white;
    width: 80%;
    position: fixed;
    padding: 3rem;
  }
}

.social-logo {
  color: #000000;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.popup_container {
  display: flex;
  width: 100%,;
  position: relative;
  justify-content: center;
  padding-bottom: 4rem;

  @include media ("<=phone") {
    padding-bottom: 0;
  }
}