@import "./fonts.scss";
@import "./breakpoints.scss";
@import "./Variables.scss";


.navbar {

  .difference {
    position: fixed;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    // bottom: 0;
    mix-blend-mode: difference;
    z-index: 99;

  @include media ("<=phone") {
    height: 40px;
    bottom: 0;
  }
  }
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.r-logo {
  background-image: url('../images/rocking-r.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 70%;
  grid-area: rLogo;
  animation: rocking 4s; 
  animation-iteration-count: infinite;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;

  @include media ("<=phone") {
    height: 100%;
  }
}

@keyframes rocking {
  0%{-webkit-transform: rotate(5deg);}
  50%{-webkit-transform: rotate(-5deg);}
  100%{-webkit-transform: rotate(5deg);}
}

.work {
  grid-area: work;
}

.info {
  grid-area: info;
}

// .close {
//   grid-area: close;
//   min-height: 60px;

//   @include media ("<=phone") {
//     min-height: 40px;
//   }

//  .nav-links{
//   font-family: "GeneralSans-Bold";
//   font-size: 1.5rem;
//   text-align: right;
//   color: #000000;
//   text-transform: uppercase;

//   @include media ("<=phone") {
//     font-size: 1rem;
//     text-align: center;
//   }
//   }
// }

.close {
  background-image: url('../images/close.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  justify-self: end;
  cursor: pointer;
  min-height: 25px;
  max-height: 40px;
  width: 25px;
  position: relative;
  right: 2rem;
  align-items: center;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  grid-area: close;

  @include media ("<=phone") {
    width: 20px;
    right: 0;
    justify-self: center;
    min-height: 100px;
  }
}

.navbar-logo {
  background-image: url('../images/r.c-logo-white.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  justify-self: start;
  cursor: pointer;
  min-height: 40px;
  width: 70%;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: absolute;

  @include media ("<=phone") {
    min-height: 30px;
    max-width: 250px;
  }
  
}

.hamburger {
  background-image: url('../images/hamburger-line-white.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  justify-self: start;
  cursor: pointer;
  min-height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  animation: rocking 2s; 
  animation-iteration-count: infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;

  @include media ("<=phone") {
    width: 20px;
  }
}

.nav-menu {
    display: grid;
    grid-template-areas: 
    'null close'
    'rLogo rLogo'
    'work info';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 5fr;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: -101vh;
    opacity: 0;
    transition: all .2s ease;
    padding: 1rem 4rem 6rem;
    justify-content: center;

    @include media ("<=phone") {
      padding: 2.25rem 2.25rem 0 2.25rem;
      grid-template-areas: 
    'rLogo rLogo'
    'work info'
    'close close';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    // bottom: -101vh;
    top:100vh;
    }
  }

.nav-item {
  display: block;
}

.nav-links {
  color: #0F5B45;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  :hover {
  transition: all 0.2s ease-out;
  }
}


.nav-menu.active {
    background: #A1C345;
    opacity: 1;
    transition: all 0.5s ease;
    top: 0;
    z-index: 999;

    @include media ("<=phone") {
      top: 0;
    }
  }

  .NavbarItems {
    position: relative;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    font-size: 3.75rem;
  }


  .menu-icon {
    display: block;
    position: absolute;
    right: 6rem;
    cursor: pointer;

    @include media ("<=phone") {
      right: 8px;
    }
  }
