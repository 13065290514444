@font-face {
font-family: 'recklesslight';
    src: url('../fonts/reckless-light-webfont.woff2') format('woff2'),
         url('../fonts/reckless-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'GeneralSans-Semibold';
  src: url('../fonts/GeneralSans-Semibold.woff2') format('woff2'),
       url('../fonts/GeneralSans-Semibold.woff') format('woff'),
       url('../fonts/GeneralSans-Semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
       -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'GeneralSans-Bold';
  src: url('../fonts/GeneralSans-Bold.woff2') format('woff2'),
       url('../fonts/GeneralSans-Bold.woff') format('woff'),
       url('../fonts/GeneralSans-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
       -webkit-font-smoothing: antialiased;
}

$RecklessLight: 'recklesslight', serif;
$GeneralSans-Semibold: 'GeneralSans-Semibold', sans-serif;
$GeneralSans-Bold: 'GeneralSans-Bold', sans-serif;


