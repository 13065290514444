@import "./Breakpoints.scss";

.info-container {
  padding: 6rem 20rem;
  overflow: hidden;
  height: 100%;
  // background: #f3f6da;

  @include media ("<=phone") {
    padding: 4rem 1rem;
    
  }

  h1{
  opacity: 1;
  text-align: center;
  font-size: 3rem;
  line-height: 1.5;
  font-weight: normal;
  z-index: 10;

  @include media ("<=phone") {
    font-size: 1.85rem;
    text-align: left;
  }

  .reveal-text {
    visibility: hidden;
    text-align: center;
  }

  .line {
    height: 70px;
    position: relative;
    overflow: hidden;
    margin-bottom: .5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    @include media ("<=tablet") {
      // height: 8vh;
      justify-content: left;
    }

    @include media ("<=phone") {
      height: 40px;
    }
  }
}

li{
  font-family: "GeneralSans-Semibold";
  font-size: 1rem;
  line-height: 1.5;
  list-style: none;
}

h2{
  font-size: 1.85rem;
}
}