@import "./Fonts.scss";
@import "./Breakpoints.scss";
@import "./Variables.scss";

* {
  box-sizing: border-box;
  margin: 0 !important;
  padding: 0;
  font-family: 'RecklessLight';
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-style: normal;
}

html {
  overflow-x: hidden;
  background: white;
  scroll-behavior: smooth;
}

main {
  // padding-top: 70px !important;

  @include media ("<=phone") {
    padding-top: 0 !important;
  }
}

section {
  // height: 95vh;
}


body {
  transition: background-color 1s ease;
  // background: var(--color);
}
